import React from 'react';
import Layout from 'components/Layout';
import SEO from 'components/Seo';
import RenderSection from 'components/RenderSection';
import { graphql } from 'gatsby';
import { PARKS_JSON_LD_SCHEMA } from 'constants';

const Park = ({ data: { datoCmsPark, allDatoCmsPark } = {}, pageContext }) => {
  const {
    sections,
    seo,
    ctaName,
    ctaUrl,
    ctaEventName,
    isExternalLink,
    ctaOfferUrl,
    ctaOfferName,
    slug,
  } = datoCmsPark || {};

  return (
    <Layout
      ctaName={ctaName}
      ctaUrl={ctaUrl}
      ctaEventName={ctaEventName}
      isExternalLink={isExternalLink}
      datoCmsPark={datoCmsPark}
      allDatoCmsPark={allDatoCmsPark}
      urlsTranslations={pageContext?.urlsTranslations}
      ctaOfferUrl={ctaOfferUrl}
      ctaOfferName={ctaOfferName}
      menuLandingPages={pageContext?.menuLandingPages}
      parkSlug={pageContext?.parkSlug}
    >
      <SEO
        title={seo?.title}
        description={seo?.description}
        jsonLdSchema={PARKS_JSON_LD_SCHEMA?.[slug]}
        jsonLdBreadcrumb={pageContext?.breadcrumbList}
        meta={[
          {
            name: 'robots',
            content: 'index, follow',
          },
        ]}
      />

      {sections?.map(({ id, title, contents }) => (
        <RenderSection key={id} contents={contents} title={title} />
      ))}
    </Layout>
  );
};

export const query = graphql`
  query ($parkSlug: String!, $locale: String!) {
    datoCmsPark(slug: { eq: $parkSlug }, locale: { eq: $locale }) {
      slug
      ctaUrl
      ctaName
      ctaEventName
      name
      isExternalLink
      ctaOfferUrl
      ctaOfferName
      seo {
        title
        description
      }
      sections {
        ...SectionContent
      }
      ...ParkLayoutContent
    }
    allDatoCmsPark(
      filter: { locale: { eq: $locale } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        ...ParkLayoutContent
      }
    }
  }
`;
export default Park;
